<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="start">
          <ion-button @click.prevent="goBack()">
            <ion-icon :icon="chevronBack"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>#{{genreSlug}}</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <div class="app-max-width">
        <div v-if="postGenres.length > 0">
          <PostFeed :post-genres="postGenres"  :post-scenes="[]"/>
        </div>
      </div>
    </ion-content>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle } from '@ionic/vue';
import { chevronBack, add, reorderThree, checkmark } from 'ionicons/icons';
import { defineComponent, ref, onMounted, computed } from 'vue';
import doAuth from '../../services/auth';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../../services/api';
import PostFeed from '../../components/PostFeed.vue';

export default defineComponent({
  name: 'Genre',
  components: {
    IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, PostFeed
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const genreSlug = ref(null);
    const postGenres = ref([]);
    const genre = ref([]);

    onMounted(() => {
      genreSlug.value = router.currentRoute._rawValue.params.slug
      getGenreBySlug()
    })

    function getGenreBySlug() {
      //presentLoading()
      apiClient.get('/api/genre/slug/'+genreSlug.value, {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        genre.value = response.data
        if(genre.value) {
          if(genre.value.length > 0) {
            postGenres.value.push(genre.value[0].id)
          }
        }
      }).catch(error => {
        console.log(error)  
      });          
    }

    function updateRoute(val) {
      router.push(val)
    }

    function goBack() {
      router.go(-1)
    }

    return {
      chevronBack, add, reorderThree, checkmark, router, updateRoute, goBack, authUser, genreSlug, genre, postGenres
    }
  },
  watch: {
    pageId: function() {
      if(this.pageId > 0) {
        this.getPage()
      }
    },
  }
});
</script>

<style scoped>

</style>